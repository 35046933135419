@import "shared/css/filterForm.scss";

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.formControl {
  margin-bottom: 10px;
}

.formControlDisplay {
  margin-bottom: 0;
}

.rangePicker {
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.policyForm {
  width: 450px;
  margin: 30px 0 60px 0;
}

.formButtonContainer {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}

.ageInfo {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 20px;
  width: 150px;
  color: #bbb;
  font-size: 12px;
}
