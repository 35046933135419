@import "./variables.scss";

@mixin image2x($imageUrl, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($imageUrl);
    background-size: $width $height;
  }
}

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}

@mixin limit-lines($lines, $lineheight) {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: $lines;
   line-height: $lineheight;
   max-height: $lines*$lineheight;
}

@mixin lt($media) {
  @if $media == M {
    @media only screen and (min-width: 375px) { @content; }
  } @else if $media == ML {
    @media only screen and (min-width: 600px) { @content; }
  } @else if $media == T {
    @media only screen and (min-width: 768px) { @content; }
  } @else if $media == SD {
    @media only screen and (min-width: 1024px) { @content; }
  } @else if $media == D {
    @media only screen and (min-width: 1280px) { @content; }
  } @else if $media == HD {
    @media only screen and (min-width: 1440px) { @content; }
  } @else {
    @media only screen and (min-width: $media) { @content; }
  }
}

@mixin fs16 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.03em;
}

@mixin fs14 {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.03em;
}

@mixin fs12 {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.03em;
}

@mixin fs10 {
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0.01em;
}

%textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Media Query Helpers work with Ant Design
@mixin media-xl {
  @media (min-width: #{$antd-media-xl-min}) and (max-width: #{$antd-media-xl-max}) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: #{$antd-media-lg-min}) and (max-width: #{$antd-media-lg-max}) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: #{$antd-media-md-min}) and (max-width: #{$antd-media-md-max}) {
    @content;
  }
}

@mixin media-sm {
  @media (min-width: #{$antd-media-sm-min}) and (max-width: #{$antd-media-sm-max}) {
    @content;
  }
}

@mixin media-xs {
  @media (min-width: #{$antd-media-xs-min}) and (max-width: #{$antd-media-xs-max}) {
    @content;
  }
}