@import "shared/css/mixins.scss";

.dashboardContainer {
  position: relative;
  background-color: rgb(255, 255, 255);
  min-height: 500px;
}

.dashboardTopControl {
  height: 40px;
  border-bottom: 1px solid #ddd;

  @include media-sm {
    height: auto;
    padding: 10px;
  }

  @include media-xs {
    height: auto;
    padding: 10px;
  }
}

.dashboardControls {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;

  @include media-sm {
    position: relative;
    width: 100%;
    height: unset;
    text-align: right;
  }

  @include media-xs {
    position: relative;
    width: 100%;
    height: unset;
    text-align: right;
  }
}

.refreshButton {
  position: absolute;
  top: 2px;
  left: 12.5px;
  z-index: 10;

  @include media-sm {
    top: 4px;
    background-color: #fff !important;
  }

  @include media-xs {
    top: 4px;
    background-color: #fff !important;
  }
}

.control {
  display: inline-block;
  padding: 10px 20px;
  color: #444;
  cursor: pointer;
  height: 100%;

  @include media-sm {
    padding: 5px 10px;
  }

  @include media-xs {
    padding: 5px 10px;
  }

  :local &:hover {
    background-color: #eee;
  }

  :local &.active {
    background-color: #1890ff;
    color: #fff;
  }

  :local & > * {
    vertical-align: middle;
  }
}

.controlContainer {
  border: 1px solid #1890ff;
  padding: 10px 20px 20px 20px;
  text-align: right;
  overflow-x: auto;
}

.controlAligner {
  display: inline-block;
  width: 500px;
  text-align: left;

  :local & > p {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.dashboardContent {
  padding: 20px;
}

.chartContainer {
  border: 1px solid #eee;
  margin: 5px;
  padding: 20px;
}
