@import "src/shared/css/variables.scss";

.btn {
  -webkit-appearance: none;
  background: #fff;
  border: 1px $color5 solid;
  color: $default_color;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  transition: .2s all;
  border-radius: 2px;
  outline: 0;

  &:hover, &:active {
    outline: 0;
  }

  &:hover {
    background: $color5;
  }

  &:disabled  {
    background: $color4 !important;
    border-color: $color4 !important;
    color: #fff !important;
    cursor: default !important;
  }

  &.color1 {
    color: #fff;
    background: $color1;
    border: 1px solid $color1;

    &:hover {
      background: $color1_hover;
      border: 1px solid $color1_hover;
    }
  }

  &.color2 {
    color: #fff;
    background: $color2;
    border: 1px solid $color2;

    &:hover {
      background: $color2_hover;
      border: 1px solid $color2_hover;
    }
  }

  &.color6 {
    color: #fff;
    background: $color6;
    border: 1px $color6 solid;

    &:hover {
      background: $color6_hover;
      border: 1px $color6_hover solid;
    }
  }

  &.transparent_white {
    color: $header_link;
    background: transparent;
    border: 1px solid $header_link;

    &:hover {
      border: 1px solid #fff;
      color: #fff;
    }
  }
}

.btn_tiny {
  height: 20px;
  line-height: 18px;
  font-size: 10px;
  padding: 0 5px;
  transition: .2s all;
  border-radius: 

  &:hover {
    background: $color5;
    border-color: $color4;
  }
}