@import "shared/css/filterForm.scss";

.formButtonContainer {
  margin: 30px 200px 0 0;
  text-align: right;
}

.form {
  margin: 30px 0 60px 0;
  width: 850px;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
  :global(input), :global(textarea), :global(.ant-select), :global(.ant-input-number), :global(.ant-calendar-picker) {
    width: 100%;
  }
}