.dataDisplay {
  width: 100%;
  margin: 5px 0;

  :local & > span {
    display: inline-block;
  }

  :local & > :global(.label) {
    width: 30%;
  }
  :local & > :global(.value) {
    width: 65%;
  }
}