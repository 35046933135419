@import "src/shared/css/variables.scss";

/*
 * Globals
 */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  //font-family: "SpoqaHanSans", "Helvetica Neue", "Apple SD Gothic Neo", "Roboto", "SamsungKorean", "Noto Sans CJK KR", "Source Han Sans", "본고딕", "Segoe UI", "Helvetica", "Arial", "나눔바른고딕", "나눔고딕", "맑은 고딕", "돋움", "Dotum", sans-serif;
  font-family: "Helvetica Neue", "Apple SD Gothic Neo", "Roboto", "SamsungKorean", "Noto Sans CJK KR", "Source Han Sans", "본고딕", "Segoe UI", "Helvetica", "Arial", "나눔바른고딕", "나눔고딕", "맑은 고딕", "돋움", "Dotum", sans-serif;
}

a {
  color: $color_link;
  text-decoration: none;

  &:hover {
    color: darken($color_link, 15%);
  }
}

ol, ul, li {
  list-style: none;
}

img {
  border: 0;
}

input {
  -webkit-appearance: none;
}

::selection {
  background: $color6;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: $color6;
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: $color6;
  color: #fff;
  text-shadow: none;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;

  background-color: $bgcolor1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // user-select: none;
}

body {
  color: $default_color;
  font-size: 12px;
}
