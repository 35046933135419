@import "shared/css/mixins.scss";

.contractDivider {
  position: relative;
  display: block;
  width: 100%;
  min-width: 100%;
  margin: 15px 0;
  padding-left: 15px;
  clear: both;
}

.contractDividerText {
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  width: 25px;
  margin-right: 15px;
  color: #444;
  margin-right: 20px;
  text-align: center;
  background-color: #ccc;
  color: #fff;
}

.contractDividerTextActive {
  background-color: rgba(0, 204, 0, 1);
  color: #fff;
}

.contractDividerLine {
  display: inline-block;
  height: 1px;
  background-color: #ebebeb;
  width: 200px;
  margin-bottom: 1px;
}

.contractPlaceholder {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
}

.contractFileInfo {
  color: #909090;
}

.contractFormDescription {
  height: 50px;

  :local :global(.antd-pro-description-list-term) {
    width: 35%;
    display: inline-block;
    vertical-align: middle;
    white-space: unset;
    margin-right: 0;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 65%;
    display: inline-block;
    vertical-align: middle;

    @include media-lg {
      padding-left: 15px;
    }
  }
}

.buttonsContainer {
  text-align: right;

  @include media-lg {
    margin-top: 40px;
  }
}