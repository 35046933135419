.container {
	position: fixed;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99999;
}

.container_inline {
	position: static;
	padding: 0;
	width: auto;
	z-index: auto;
}

.alert {
	margin:10px;
	.inner {
		padding: 15px 35px 15px 15px;
		border-radius: 4px;
		border-width: 1px;
		border-style: solid;
		position: relative;
	}
}

.success {
	background-color: #d4fad6;
	border-color: #c5f7be;
	color: #3c763d;
}

.warning {
	background-color: #faebd4;
	border-color: #f7d7be;
	color: #8a6d3b;
}

.info {
	background-color: #d4e9fa;
	border-color: #b5e4f6;
	color: #31708f;
}

.error {
	background-color: #fad7d4;
	border-color: #f7bec3;
	color: #a94442;
}

.close {
	position: absolute;
	background: transparent;
	color: inherit;
	border: 0;
	padding:0;
	top: 10px;
	right: 10px;
	cursor: pointer;
	font-size: 21px;
	line-height: 1;
	font-weight: 500;
	opacity: .2;
	transition: opacity .3s;
}
.close:hover {
	opacity: 1;
}

.close:focus {
	outline: 0;
}

.animate_enter {
	opacity: 0.01;
}
.animate_enter_active {
	opacity: 1;
	transition: opacity .3s;
}
.animate_leave {
  opacity: 1;
}
.animate_leave_active {
	opacity: 0.01;
	transition: opacity .3s;
}
