/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

.contractDivider {
  position: relative;
  display: block;
  width: 100%;
  min-width: 100%;
  margin: 15px 0;
  padding-left: 15px;
  clear: both;
}

.contractDividerText {
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  width: 25px;
  margin-right: 15px;
  color: #444;
  margin-right: 20px;
  text-align: center;
  background-color: #ccc;
  color: #fff;
}

.contractDividerTextActive {
  background-color: #00cc00;
  color: #fff;
}

.contractDividerLine {
  display: inline-block;
  height: 1px;
  background-color: #ebebeb;
  width: 200px;
  margin-bottom: 1px;
}

.contractPlaceholder {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 50px;
  text-align: center;
}

.contractFileInfo {
  color: #909090;
}

.contractFormDescription {
  height: 50px;
}

.contractFormDescription :local :global(.antd-pro-description-list-term) {
  width: 35%;
  display: inline-block;
  vertical-align: middle;
  white-space: unset;
  margin-right: 0;
}

.contractFormDescription :local :global(.antd-pro-description-list-detail) {
  width: 65%;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contractFormDescription :local :global(.antd-pro-description-list-detail) {
    padding-left: 15px;
  }
}

.buttonsContainer {
  text-align: right;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .buttonsContainer {
    margin-top: 40px;
  }
}
