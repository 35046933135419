.noticeContainer {
  background-color: rgb(255, 255, 255);
  padding: 50px;
  min-height: 500px;
}

// TUI Editor Color picker fix
// https://github.com/nhn/tui.editor/issues/544#issuecomment-505670698
:global(.tui-popup-color .te-apply-button) {
  bottom: 135px;
  color: black;
}

// :global(.tui-colorpicker-clearfix) {
//   vertical-align: middle;
// }

// :global(.tui-popup-color .tui-colorpicker-container .tui-colorpicker-palette-hex) {
//   height: 20px;
//   width: 80px;
// }

// :global(.te-apply-button) {
//   bottom: 0;
//   line-height: 15px;
// }