@import "shared/css/mixins.scss";
@import "shared/css/filterForm.scss";

.onlyVisibleInWidescreen {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.leftAlignedDescriptionList {
  :local :global(.antd-pro-description-list-term) {
    width: 30%;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    white-space: unset;
    margin-right: 0;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 70%;
    height: 40px;
    display: inline-block;
    vertical-align: middle;

    input, div {
      max-width: 200px;
    }
  }
}