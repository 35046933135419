@import "shared/css/variables.scss";
@import "shared/css/mixins.scss";
@import "shared/css/filterForm.scss";


.toolIcon {
  color: $color4;

  :local &:hover {
    color: darken($color4, 15%);
  }
}

.formButtonContainer {
  margin: 30px 200px 0 0;
  text-align: right;
}

.form {
  margin: 30px 0 60px 0;
  width: 850px;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
  :global(input), :global(textarea), :global(.ant-select), :global(.ant-input-number), :global(.ant-calendar-picker) {
    width: 100%;
  }
}

.formControlDisplay {
  margin-bottom: 0;
}

// Themes
$border-color: #ddd;

// Component Layout
$category-control-height: 50px;


.bannerContainer {
  background-color: rgb(255, 255, 255);
  padding: 25px;
  min-height: 500px;
}

.bannerCategoriesWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid $border-color;
  padding: 15px;
}

.tagForm {
  width: 450px;
  margin: 30px 0 0 0;
}