.paginationItem {
	display: block;
  padding: 0 6px;
  color: rgba(0,0,0,0.65);
  -webkit-transition: none;
  transition: none;
}

.paginationItemActive {
  color: #1890ff;
}

.inlineBlock {
  display: inline-block;
  vertical-align: middle;
}

.jumper {
  width: 50px;
}