@import "shared/css/mixins.scss";

.textChart {
  text-align: center;
  padding: 20px;
}

.value {
  font-size: 48px;

  @include media-lg {
    font-size: 24px;
  }

  @include media-md {
    font-size: 24px;
  }

  @include media-sm {
    font-size: 24px;
  }

  @include media-xs {
    font-size: 24px;
  }
}

.label {
  font-size: 14px;
}