.passwordUpdateFormContainer {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  background-color: #f0f2f5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

$formWidth: 450px;
$formHeight: 550px;

.passwordUpdateForm {
  position: absolute;
  top: 100px;
  left: 50%;
  margin-left: -($formWidth / 2);
  width: $formWidth;
  height: $formHeight;
  background-color: #fff;
  padding: 50px 40px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.infoText {
  margin-bottom: 20px;
}

.passwordRules {
  margin: 20px 0 40px 0;
}

.submitButton {
  border-radius: 30px;
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0;
  width: 100px;

  :local &:hover {
    background-color: #1990ff !important;
    color: #fff !important;
  }
}