:local .form > :global(.ant-row) {
  margin: 20px 0;
}

:local .form > :global(.ant-row):first-child {
  margin-top: 0;
}

:local .form > :global(.ant-row):last-child {
  margin-bottom: 0;
}

.label {
  font-weight: bold;
}

.redText {
  color: red;
}

.discountRateLabel {
  display: block;
  margin-top: 5px;
}

.inputError {
  border-color: red;
}

.error {
  color: red;
  margin-top: 5px;
}
