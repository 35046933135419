/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.content {
  padding: 32px 0;
  flex: 1;
}
@media (max-width: 576px) {
  .content {
    padding: 32px 8px;
  }
}
@media (min-width: 768px) {
  .container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .content {
    padding: 112px 0 24px 0;
  }
}
.top {
  text-align: center;
}
.header {
  height: 44px;
  line-height: 44px;
}
.header a {
  text-decoration: none;
}
.logoContainer > * {
  display: inline-block;
  vertical-align: middle;
}
.logo {
  height: 40px;
  margin-right: 24px;
}
.title {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
  left: -10px;
}
.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
.main {
  width: 368px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .main {
    width: 95%;
  }
}
.main .icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.main .icon:hover {
  color: #1890ff;
}
.main .other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.main .other .register {
  float: right;
}
.login {
  margin-top: 30px;
}
.login :global .ant-form-item {
  margin-bottom: 24px;
}
.login .getCaptcha {
  display: block;
  width: 100%;
  height: 42px;
}
/* 0.5 초씩 바운스 효과 */
.bounce {
  position: relative;
  /* 파폭 */
  -moz-animation: bounce 0.5s infinite linear;
  /* 크롬 */
  -webkit-animation: bounce 0.5s infinite linear;
  -o-animation: bounce 0.5s infinite linear;
  animation: bounce 0.5s infinite linear;
  font-size: 20px;
}
@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  70% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
@-ms-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  70% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  70% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  70% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -5px;
  }
  70% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
