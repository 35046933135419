/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content {
  height: 100%;
  margin: 24px 0 0 0;
}
@media (min-width: 768px) {
  .content {
    margin: 24px 24px 0;
  }
}
