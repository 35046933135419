.profileContainer {
  padding: 20px 40px;
  background-color: #fff;
}

.profileForm {
  width: 450px;
  margin: 30px 0 60px 0;
}

h2 {
  text-align: center;
}

.qrCode {
  text-align: center;
}
