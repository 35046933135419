.faqList {
  position: relative;
}

.listTop {
  text-align: right;
  height: 30px;
  margin-bottom: 24px;
}

.listControl {
  position: relative;
}

.createButton {
  position: absolute;
  top: 0;
  left: 0;
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

.pageSizeWrapper {
  position: absolute;
  top: 0;
  right: 0;
}
