/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

.filterForm :global(.ant-form-item) {
  margin-bottom: 12px;
}

.filterForm :global(.ant-form-item-label) {
  line-height: 20px;
  padding: 0 0;
}

.filterForm :global(.ant-form-item-label) > label {
  color: #505050;
  font-size: 12px;
}

.filterForm :global(.ant-form-item-label) > label:after {
  content: '';
}

@media (min-width: 1200px) and (max-width: 9999999px) {
  .rangePicker {
    width: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .rangePicker {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

@media screen and (max-width: 1199px) {
  .onlyVisibleInWidescreen {
    display: none !important;
  }
}

.summary {
  background-color: #d8eeff;
  padding: 20px 60px;
  margin: 10px 20px;
  font-size: 24px;
  -webkit-box-shadow: 5px 5px 5px 0px #d6d6d6;
  -moz-box-shadow: 5px 5px 5px 0px #d6d6d6;
  box-shadow: 5px 5px 5px 0px #d6d6d6;
}

@media (min-width: 768px) and (max-width: 991px) {
  .summary {
    font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .summary {
    padding: 20px 30px;
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summary {
    padding: 20px 30px;
    font-size: 16px;
  }
}

:local .summary > span {
  margin-right: 20px;
}

@media (min-width: 0px) and (max-width: 575px) {
  :local .summary > span {
    display: block;
  }
}

.summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
  width: 15px;
  height: 15px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
    width: 8px;
    height: 8px;
  }
}

:local .summaryStatus > :global(.userInfo) {
  padding-left: 10px;
}

.summaryBalance {
  margin-left: 80px;
}

:local .summaryBalance > :global(.currency) {
  font-size: 20px;
}

@media (min-width: 576px) and (max-width: 767px) {
  :local .summaryBalance > :global(.currency) {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  :local .summaryBalance > :global(.currency) {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .summaryBalance {
    margin-left: 40px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summaryBalance {
    margin-top: 10px;
    margin-left: 0px;
  }
}

.userStatusSelect {
  position: absolute;
  top: -5px;
}

.userStatusText {
  position: absolute;
  top: 0px;
}

.deleteButtonContainer {
  margin-left: 20px;
}

.deleteButton {
  background-color: #ca0000;
  color: #fff;
  border: none;
}

.deleteButton:focus {
  background-color: #7e0000;
  color: #fff;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-term) {
  width: 30% !important;
  height: 50px;
  display: inline-block !important;
  vertical-align: middle !important;
  white-space: unset !important;
  margin-right: 0 !important;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-detail) {
  width: 70% !important;
  height: 50px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.tableContainer :local td {
  height: 70px;
}

.loadPGAccountsButton {
  position: absolute;
  top: -5px;
  right: 5px;
}
