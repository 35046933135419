.dataDisplay {
  width: 100%;
  margin: 5px 0;
}

:local .dataDisplay > span {
  display: inline-block;
}

:local .dataDisplay > :global(.label) {
  width: 30%;
}

:local .dataDisplay > :global(.value) {
  width: 65%;
}
