@import "src/shared/css/app.scss";

.notsupported {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background: #fff;
  color: #1e1e1e;
  text-align: center;
  font-size: 12px;
  line-height: 150%;
  h3 {
    font-weight: bold;
    font-size: 22px;
    margin: 0 0 20px 0;
  }
  table {
    width: 400px;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    margin: 20px auto 0;
    td {
      width: 50%;
      text-align: center;
    }
  }
  a, a:visited {
    display: inline-block;
    padding: 10px 20px;
    text-align: center;
    border-radius: 20px;
    color: #1e1e1e;
    border: solid 2px #ccc;
    margin: 10px auto 0;
    transition: all .2s;
    &:hover {
      color: #fff;
      background: #1e1e1e;
      border: solid 2px #ccc;
      transition: all .2s;
    }
  }
  .title {
    font-weight: bold;
  }
}

.disconnected, .connected {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,0,0,.8);
  width: 100%;
  text-align: center;
  padding: 16px;
  font-size: 12px;
  color: #fff;
  cursor: default;
  transition: all .3s;
  &:before {
    content: '';
    display: block;
    width: 21px;
    height: 23px;
    background: url("/shared/images/ico_reload_x2.png") center no-repeat;
    background-size: 21px 23px;
    margin: 0 auto 8px;
    cursor: pointer;
  }
}

.connected {
  top: -100px;
}

