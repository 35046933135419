.filterForm :global(.ant-form-item) {
  margin-bottom: 12px;
}

.filterForm :global(.ant-form-item-label) {
  line-height: 20px;
  padding: 0 0;
}

.filterForm :global(.ant-form-item-label) > label {
  color: #505050;
  font-size: 12px;
}

.filterForm :global(.ant-form-item-label) > label:after {
  content: '';
}

@media screen and (max-width: 1199px) {
  .onlyVisibleInWidescreen {
    display: none !important;
  }
}

.buttonContainer {
  width: 120px;
}

:global .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-term) {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  white-space: unset;
  margin-right: 0;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-detail) {
  width: 70%;
  display: inline-block;
  vertical-align: middle;
}
