.filterForm :global(.ant-form-item) {
  margin-bottom: 12px;
}

.filterForm :global(.ant-form-item-label) {
  line-height: 20px;
  padding: 0 0;
}

.filterForm :global(.ant-form-item-label) > label {
  color: #505050;
  font-size: 12px;
}

.filterForm :global(.ant-form-item-label) > label:after {
  content: '';
}

.formButtonContainer {
  margin: 30px 200px 0 0;
  text-align: right;
}

.loadingSpinner {
  position: absolute;
  top: 5px;
  left: -30px;
}

.form {
  margin: 30px 0 60px 0;
  width: 850px;
}

@media screen and (max-width: 1199px) {
  .form {
    width: 100%;
  }
}

.form :global(input), .form :global(textarea), .form :global(.ant-select), .form :global(.ant-input-number), .form :global(.ant-calendar-picker) {
  width: 100%;
}

.tagButton {
  margin-left: 4px;
  vertical-align: middle;
}
