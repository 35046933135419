@import "shared/css/mixins.scss";
@import "shared/css/filterForm.scss";

.rangePicker {
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    width: 250px;
  }
}

.onlyVisibleInWidescreen {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.summary {
  background-color: #d8eeff;
  padding: 20px 60px;
  margin: 10px 20px;
  font-size: 24px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);

  @include media-lg {
    font-size: 18px;
    padding: 20px 20px;
  }

  @include media-md {
    font-size: 16px;
    padding: 20px 40px;
  }

  @include media-sm {
    padding: 20px 30px;
    font-size: 18px;
  }

  @include media-xs {
    padding: 20px 30px;
    font-size: 16px;
  }

  :local & > span {
    display: inline-block;
    vertical-align: middle;

    @include media-xs {
      display: block;
      margin-bottom: 15px;
    }

    & > button {
      margin-left: 50px;

      @include media-xs {
        margin: 0;
      }
    }
  }
}

.summaryStatus {
  margin-right: 30px;

  :global(.ant-badge > .ant-badge-status-dot) {
    width: 15px;
    height: 15px;

    @include media-lg {
      width: 10px;
      height: 10px;
    }

    @include media-sm {
      width: 10px;
      height: 10px;
    }

    @include media-xs {
      width: 8px;
      height: 8px;
    }
  }
}

.summaryBalance {
  margin-left: 20px;
  margin-right: 40px;

  :local & > :global(.currency) {
    font-size: 20px;

    @include media-sm {
      font-size: 14px;
    }

    @include media-xs {
      font-size: 12px;
    }
  }

  @include media-sm {
    margin-left: 40px;
  }

  @include media-xs {
    margin-top: 10px;
    margin-left: 0px;
  }
}

.merchantsLoadSpinner {
  position: absolute;
  top: 10px;
  right: 18px;
}

.merchantsRefreshButton {
  position: absolute;
  top: 10px;
  right: 18px;
  z-index: 10;
  cursor: pointer;
}

.merchantsLoadFailIcon {
  position: absolute;
  top: 10px;
  right: 38px;
  z-index: 10;
  color: #e26363;
}

.statusBadge {
  position: absolute;
  top: 0;
}

.deleteButton {
  background-color: $theme-customDelete-bgColor;
  color: $theme-customDelete-fontColor;
  border: none;

  &:focus {
    background-color: darken($theme-customDelete-bgColor, 15%);
    color: $theme-customDelete-fontColor;
  }
}

.transactionLogContainer {
  position: relative;
}

.toggleMerchantGroup {
  position: absolute;
  top: 0;
  right: 0;

  :local(& > span) {
    margin-right: 10px;
  }
}

.leftAlignedDescriptionList {
  :local :global(.antd-pro-description-list-term) {
    width: 30%;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    white-space: unset;
    margin-right: 0;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 70%;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
  }
}

.paymentStatusSelect {
  position: absolute;
  top: -5px;
  width: 120px;
}

.tableColumn {
  background-color: #fafafa !important;
}
