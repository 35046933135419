/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.standardTable :global .ant-table-pagination {
  margin-top: 24px;
}
.standardTable .tableAlert {
  margin-bottom: 16px;
}
:local .standardTable .tableAlert > div {
  padding: 8px 15px;
}
:local .standardTable .tableAlert > div > * {
  display: inline-block;
  vertical-align: middle;
}
:local .standardTable .tableAlert > div > * > * {
  display: inherit;
  vertical-align: inherit;
  margin-right: 20px;
}
:local .standardTable .tableAlert > div > i {
  position: unset;
  margin-right: 10px;
}
