/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.prefixIcon {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}
.submit {
  width: 100%;
  margin-top: 24px;
}
.maskToggle {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
}
.maskToggle:hover {
  color: #1890ff;
  background-color: rgba(129, 194, 255, 0.25);
  border-radius: 5px;
}
:local .passwordInput :local input::input-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  opacity: 1;
}
:local .passwordInput :local input::-webkit-input-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
:local .passwordInput :local input:-ms-input-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
:local .passwordInput :local input::-ms-input-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
:local .passwordInput :local input:-moz-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
:local .passwordInput :local input::-moz-placeholder {
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
