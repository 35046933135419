/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

.toolIcon {
  color: #919DAE;
}

:local .toolIcon:hover {
  color: #67768c;
}
