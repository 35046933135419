.noticeContainer {
  background-color: white;
  padding: 50px;
  min-height: 500px;
}

:global(.tui-popup-color .te-apply-button) {
  bottom: 135px;
  color: black;
}
