@import "shared/css/filterForm.scss";

.onlyVisibleInWidescreen {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.buttonContainer {
  width: 120px;
}

// Remove bottom line in Collapse
:global {
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }
}

.leftAlignedDescriptionList {
  :local :global(.antd-pro-description-list-term) {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
    white-space: unset;
    margin-right: 0;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
  }
}