@import '~antd/lib/style/themes/default.less';

.standardTable {
  :global {
    .ant-table-pagination {
      margin-top: 24px;
    }
  }

  .tableAlert {
    margin-bottom: 16px;

    :local & {
      > div {
        padding: 8px 15px;
      }

      > div > * {
        display: inline-block;
        vertical-align: middle;

        & > * {
          display: inherit;
          vertical-align: inherit;
          margin-right: 20px;
        }
      }
      > div > i {
        position: unset;
        margin-right: 10px;
      }
    }
  }
}