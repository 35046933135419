/**
 *  Define scss variables here.
 */

$point_color: #3BBEE0;

/* default text color  */
$default_color: #596070;

$bgcolor1: #F0F6FC;
$bgcolor2: #f7fbff;

$color1: #FE4A49;
$color1_hover: #DC4241;

$color2: #174C94;
$color2_hover: #133E79;

$color3: #022553;
$color4: #919DAE;
$color5: #EBEEF6;
$color6: #3BBEE0;
$color6_hover: #35A8C6;

$color7: #F5F8FF;
$color_link: #1890ff;

$header_link: #A3AFC0;

.default_color {
  color: #596070;
}

.color1 {
  color: $color1;
}

.color2 {
  color: $color2;
}

.color3 {
  color: $color3;
}

.color4 {
  color: $color4;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}


// Variables for Media Query work with Ant Design
$antd-media-xl-min: 1200px;
$antd-media-xl-max: 9999999px;
$antd-media-lg-min: 992px;
$antd-media-lg-max: $antd-media-xl-min - 1px;
$antd-media-md-min: 768px;
$antd-media-md-max: $antd-media-lg-min - 1px;
$antd-media-sm-min: 576px;
$antd-media-sm-max: $antd-media-md-min - 1px;
$antd-media-xs-min: 0px;
$antd-media-xs-max: $antd-media-sm-min - 1px;

// Custom Theme values
$theme-customDelete-bgColor: #ca0000;
$theme-customDelete-fontColor: #fff;