.bannerContainer {
  background-color: white;
  padding: 25px;
  min-height: 500px;
}

.bannerCategoriesWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #ddd;
  padding: 15px;
}

.categoryButtonContainer {
  width: 200px;
}

:local .categoryButtonContainer > button {
  display: inline-block;
  margin-right: 5px;
}
