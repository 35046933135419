.filterForm :global(.ant-form-item) {
  margin-bottom: 12px;
}

.filterForm :global(.ant-form-item-label) {
  line-height: 20px;
  padding: 0 0;
}

.filterForm :global(.ant-form-item-label) > label {
  color: #505050;
  font-size: 12px;
}

.filterForm :global(.ant-form-item-label) > label:after {
  content: '';
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.formControl {
  margin-bottom: 10px;
}

.formControlDisplay {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .rangePicker {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.policyForm {
  width: 450px;
  margin: 30px 0 60px 0;
}

.formButtonContainer {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}

.ageInfo {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 20px;
  width: 150px;
  color: #bbb;
  font-size: 12px;
}
