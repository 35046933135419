.title {
  font-size: 14px;
  margin-bottom: 10px;
}

.intervalGroup {
  padding-left: 20px;
}

:local .intervalGroup + .intervalGroup {
  border-left: 1px solid #eee;
}
