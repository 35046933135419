@import "shared/css/variables.scss";
@import "shared/css/mixins.scss";
@import "shared/css/filterForm.scss";

.rangePicker {
  @include media-xl {
    width: 250px;
  }
  @include media-lg {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.onlyVisibleInWidescreen {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.summary {
  background-color: #d8eeff;
  padding: 20px 60px;
  margin: 10px 20px;
  font-size: 24px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);

  @include media-md {
    font-size: 20px;
  }

  @include media-sm {
    padding: 20px 30px;
    font-size: 18px;
  }

  @include media-xs {
    padding: 20px 30px;
    font-size: 16px;
  }

  :local & > span {
    margin-right: 20px;
    
    @include media-xs {
      display: block;
    }
  }
}

.summaryStatus {
  :global(.ant-badge > .ant-badge-status-dot) {
    width: 15px;
    height: 15px;

    @include media-sm {
      width: 10px;
      height: 10px;
    }

    @include media-xs {
      width: 8px;
      height: 8px;
    }
  }

  :local & > :global(.userInfo) {
    padding-left: 10px;
  }
}

.summaryBalance {
  margin-left: 80px;

  :local & > :global(.currency) {
    font-size: 20px;

    @include media-sm {
      font-size: 14px;
    }

    @include media-xs {
      font-size: 12px;
    }
  }

  @include media-sm {
    margin-left: 40px;
  }

  @include media-xs {
    margin-top: 10px;
    margin-left: 0px;
  }
}

.userStatusSelect {
  position: absolute;
  top: -5px;
}

.userStatusText {
  position: absolute;
  top: 0px;
}

.deleteButtonContainer {
  margin-left: 20px;
}

.deleteButton {
  background-color: $theme-customDelete-bgColor;
  color: $theme-customDelete-fontColor;
  border: none;

  &:focus {
    background-color: darken($theme-customDelete-bgColor, 15%);
    color: $theme-customDelete-fontColor;
  }
}

.leftAlignedDescriptionList {
  :local :global(.antd-pro-description-list-term) {
    width: 30% !important;
    height: 50px;
    display: inline-block !important;
    vertical-align: middle !important;
    white-space: unset !important;
    margin-right: 0 !important;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 70% !important;
    height: 50px;
    display: inline-block !important;
    vertical-align: middle !important;
  }
}

.tableContainer {
  :local td {
    height: 70px;
  }
}

.loadPGAccountsButton {
  position: absolute;
  top: -5px;
  right: 5px;
}

.promotionGroupForm {
  margin: 30px 0 60px 0;
  width: 85%;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }
}

.formButtonContainer {
  padding: 0 30px;
}
