.popup_on, .popup_off {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity .2s;
}

.popup_off {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}

.popup_wrapper {
  display: table;
  width: 100%;
  height: 100%;
}
