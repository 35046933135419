/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

.btn {
  -webkit-appearance: none;
  background: #fff;
  border: 1px #EBEEF6 solid;
  color: #596070;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  transition: .2s all;
  border-radius: 2px;
  outline: 0;
}

.btn:hover, .btn:active {
  outline: 0;
}

.btn:hover {
  background: #EBEEF6;
}

.btn:disabled {
  background: #919DAE !important;
  border-color: #919DAE !important;
  color: #fff !important;
  cursor: default !important;
}

.btn.color1 {
  color: #fff;
  background: #FE4A49;
  border: 1px solid #FE4A49;
}

.btn.color1:hover {
  background: #DC4241;
  border: 1px solid #DC4241;
}

.btn.color2 {
  color: #fff;
  background: #174C94;
  border: 1px solid #174C94;
}

.btn.color2:hover {
  background: #133E79;
  border: 1px solid #133E79;
}

.btn.color6 {
  color: #fff;
  background: #3BBEE0;
  border: 1px #3BBEE0 solid;
}

.btn.color6:hover {
  background: #35A8C6;
  border: 1px #35A8C6 solid;
}

.btn.transparent_white {
  color: #A3AFC0;
  background: transparent;
  border: 1px solid #A3AFC0;
}

.btn.transparent_white:hover {
  border: 1px solid #fff;
  color: #fff;
}

.btn_tiny {
  height: 20px;
  line-height: 18px;
  font-size: 10px;
  padding: 0 5px;
  transition: .2s all;
  border-radius: .btn_tiny;
}

.btn_tiny :hover {
  background: #EBEEF6;
  border-color: #919DAE;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.popup_container_on {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.popup {
  width: 100%;
  max-width: 360px;
  height: 290px;
  min-height: 290px;
  border-radius: 2px;
  z-index: 10001;
  background-color: #fff;
  display: table;
  margin: 0 auto;
}

.popup .inner {
  display: table-cell;
  vertical-align: middle;
  padding: 20px;
}

.popup .inner .message {
  padding: 20px 20px 30px;
  font-size: 16px;
  line-height: 140%;
  font-weight: bold;
}

.popup .inner .fixButton {
  padding: 11px 16px;
  line-height: 140%;
  border: 1px solid #EBEEF6;
  display: inline-block;
  margin: 20px auto;
  color: #3BBEE0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: .2s all;
  border-radius: 2px;
}

.popup .inner .fixButton:hover {
  background: #3BBEE0;
  color: #fff;
}

.popup .inner .buttons {
  padding-bottom: 10px;
}

.popup .inner .buttons button {
  width: 100px;
  height: 40px;
  line-height: 38px;
  margin: 0 7px;
}

.popup .inner .buttons .btn_close {
  font-size: 13px;
}

.popup .inner.withTitle {
  vertical-align: top;
  padding: 0 0 20px;
}

.popup .inner.withTitle .title {
  text-align: left;
  padding: 20px;
  border-bottom: solid 1px #EBEEF6;
  font-size: 18px;
  font-weight: bold;
  color: #022553;
}

.popup .inner.withTitle .buttons {
  text-align: right;
  padding-right: 13px;
}

.popup .inner.withTitle .table {
  display: table;
  table-layout: fixed;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  margin: 10px 0 30px;
}

.popup .inner.withTitle .table .table_row {
  display: table-row;
}

.popup .inner.withTitle .table .label, .popup .inner.withTitle .table .content {
  display: table-cell;
  width: 30%;
  vertical-align: middle;
  text-align: left;
  padding: 0 5px 0 20px;
  height: 40px;
  line-height: 40px;
  word-break: break-all;
  line-height: 130%;
}

.popup .inner.withTitle .table .content {
  width: 70%;
  text-align: right;
  padding: 0 20px 0 5px;
}

.popup .inner.withTitle .table .currency {
  font-weight: normal;
}
