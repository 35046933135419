$listSelectColor: #e2f7ff;
$borderColor: #e8e8e8;

.listSelect {
  transition-duration: .3s;
  -webkit-transition-duration: .3s;
  border: 1px solid $borderColor;
  height: 235px;
  overflow: scroll;
  &.small {
    height: 135px;
  }
}

.listItem {
  padding: 5px 0 5px 15px;
  width: 100%;
  cursor: pointer;
  transition-duration: .3s;
  -webkit-transition-duration: .3s;

  :local &:hover {
    background-color: $listSelectColor;
  }

  :local & > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.error {
  border-color: #ff0000;
}

.listItemCheckbox {
  margin-right: 10px;
}

.listItemEmpty {
  width: 100%;
  text-align: center;
}
