.top20Chart :global(.recharts-legend-wrapper) {
  top: 5px;
  width: 150px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.isMobile :global(.recharts-legend-wrapper) {
  top: 250px;
  width: 100% !important;
}

.legendItem {
  position: relative;
  display: inline-block;
  margin: 0 5px 5px 0;
  width: 200px;
}

.mobileLegendItem {
  width: 100%;
}

.legendIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.hoverText {
  position: fixed;
  border: 1px solid #444;
  background-color: white;
  padding: 5px;
  z-index: 100;
}

.tooltip {
  background-color: #fff;
  border: 1px solid #444;
  padding: 10px;
}
