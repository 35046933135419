.faqForm {
  position: relative;
  width: 100%;
}

.title > h1 {
  font-size: 24px;
  margin-left: 24px;
  margin-bottom: 32px;
}

.title > * {
  display: inline-block;
  margin-right: 20px;
}

.formControl {
  margin-bottom: 10px;
}

.contentError :global(.tui-editor-defaultUI) {
  border-color: red;
}

:global(.tui-editor-contents h1) {
  border: none;
}

:global(.tui-editor-contents h2) {
  border: none;
}
