/* Using Anchor tag in columns have serious problems.
 * First: If use something like this, <a href="#"> ... </a>, there's no way to prevent of default behaviour of Anchor tag.
 * Second: So, just use something like this: <a href="javascript:;"> ... </a>, after clicking the anchor tag, your website instantly work super damn slow! Annoying thing is that this is actually in the official Ant Design documentation.
 * So only option is just styles to act something like anchor tag.
 */
.linkText {
  touch-action: manipulation;
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
