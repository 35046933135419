.form {
  :local & > :global(.ant-row) {
    margin: 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.label {
  font-weight: bold;
}

.redText {
  color: red;
}

.discountRateLabel {
  display: block;
  margin-top: 5px;
}

.inputError {
  border-color: red;
}

.error {
  color: red;
  margin-top: 5px;
}