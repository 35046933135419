@import "shared/css/filterForm.scss";

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.formControl {
  margin-bottom: 10px;
}

.formControlDisplay {
  margin-bottom: 0;
}

.rangePicker {
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.promotionForm {
  margin: 30px 0 60px 0;
  min-width: 850px;
  width: 1000px;

  @media screen and (max-width: 1199px) {
    width: 100%;
  }

  :global(input),
  :global(textarea),
  :global(.ant-select),
  :global(.ant-input-number),
  :global(.ant-calendar-picker) {
    width: 100%;
  }
}

.formButtonContainer {
  margin: 30px 200px 0 0;
  text-align: right;
}

.simulateButton {
  position: absolute;
  top: 40px;
  left: -100px;
}

.loadingSpinner {
  position: absolute;
  top: 5px;
  left: -30px;
}

.simulateSpinner {
  position: absolute;
  top: 50px;
  left: -125px;
}

.fieldInfoBelow {
  margin-bottom: 0;
  color: #8a8a8a;

  :global(.ant-form-item-control) {
    line-height: 20px;
  }
}

.fieldInfoRight {
  position: relative;

  :global(p) {
    color: #8a8a8a;
    position: absolute;
    top: 10px;
  }
}

.redText {
  color: red;
}