@import "src/shared/css/variables.scss";
@import "src/shared/css/button.scss";
@import "src/shared/css/table.scss";

.popup_container_on {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.popup {
  width: 100%;
  max-width: 360px;
  height: 290px;
  min-height: 290px;
  border-radius: 2px;
  z-index: 10001;
  background-color: #fff;
  display: table;
  margin: 0 auto;

  .inner {
    display: table-cell;
    vertical-align: middle;
    padding: 20px;

    .message {
      padding: 20px 20px 30px;
      font-size: 16px;
      line-height: 140%;
      font-weight: bold;
    }

    .fixButton {
        padding: 11px 16px;
        line-height: 140%;
        border: 1px solid $color5;
        display: inline-block;
        margin: 20px auto;
        color: $color6;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        transition: .2s all;
        border-radius: 2px;
        &:hover {
            background: $color6;
            color: #fff;
        }
    }

    .buttons {
        padding-bottom: 10px;
      button {
        width: 100px;
        height: 40px;
        line-height: 38px;
        margin: 0 7px;
      }
      .btn_close {
        font-size: 13px;
      }
    }

    &.withTitle {
      vertical-align: top;
      padding: 0 0 20px;
      .title {
        text-align: left;
        padding: 20px;
        border-bottom: solid 1px $color5;
        font-size: 18px;
        font-weight: bold;
        color: $color3;
      }
      .buttons {
        text-align: right;
        padding-right: 13px;
      }
      .table {
        display: table;
        table-layout: fixed;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        margin: 10px 0 30px;
        .table_row {
            display: table-row;
        }
        .label {
            display: table-cell;
            width: 30%;
            vertical-align: middle;
            text-align: left;
            padding: 0 5px 0 20px;
            height: 40px;
            line-height: 40px;
            word-break: break-all;
            line-height: 130%;
        }
        .content {
            @extend .label;
            width: 70%;
            text-align: right;
            padding: 0 20px 0 5px;
        }
        .currency {
            font-weight: normal;
        }
      }
    }
  }
}