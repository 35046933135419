/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

.logoutTimer {
  display: inline-block;
  color: #444444;
  margin-right: 10px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .logoutTimer {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .logoutTimer {
    display: none;
  }
}

.clockIcon {
  margin-right: 5px;
}

.extendText {
  margin-left: 5px;
  color: #1890ff;
  cursor: pointer;
}

:local .extendText:hover {
  text-decoration: underline;
}

.warning {
  color: red;
}
