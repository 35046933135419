@charset "UTF-8";
/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

/*
 * Globals
 */
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Helvetica Neue", "Apple SD Gothic Neo", "Roboto", "SamsungKorean", "Noto Sans CJK KR", "Source Han Sans", "본고딕", "Segoe UI", "Helvetica", "Arial", "나눔바른고딕", "나눔고딕", "맑은 고딕", "돋움", "Dotum", sans-serif;
}

a {
  color: #1890ff;
  text-decoration: none;
}

a:hover {
  color: #0069cb;
}

ol, ul, li {
  list-style: none;
}

img {
  border: 0;
}

input {
  -webkit-appearance: none;
}

::selection {
  background: #3BBEE0;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #3BBEE0;
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #3BBEE0;
  color: #fff;
  text-shadow: none;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
  background-color: #F0F6FC;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #596070;
  font-size: 12px;
}

.notsupported {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background: #fff;
  color: #1e1e1e;
  text-align: center;
  font-size: 12px;
  line-height: 150%;
}

.notsupported h3 {
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 20px 0;
}

.notsupported table {
  width: 400px;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  margin: 20px auto 0;
}

.notsupported table td {
  width: 50%;
  text-align: center;
}

.notsupported a, .notsupported a:visited {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border-radius: 20px;
  color: #1e1e1e;
  border: solid 2px #ccc;
  margin: 10px auto 0;
  transition: all .2s;
}

.notsupported a:hover, .notsupported a:visited:hover {
  color: #fff;
  background: #1e1e1e;
  border: solid 2px #ccc;
  transition: all .2s;
}

.notsupported .title {
  font-weight: bold;
}

.disconnected, .connected {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  text-align: center;
  padding: 16px;
  font-size: 12px;
  color: #fff;
  cursor: default;
  transition: all .3s;
}

.disconnected:before, .connected:before {
  content: '';
  display: block;
  width: 21px;
  height: 23px;
  background: url("/shared/images/ico_reload_x2.png") center no-repeat;
  background-size: 21px 23px;
  margin: 0 auto 8px;
  cursor: pointer;
}

.connected {
  top: -100px;
}
