// Themes
$border-color: #ddd;

// Component Layout
$category-control-height: 50px;

.bannerContainer {
  background-color: rgb(255, 255, 255);
  padding: 25px;
  min-height: 500px;
}

.bannerCategoriesWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid $border-color;
  padding: 15px;
}

.categoryButtonContainer {
  width: 200px;

  :local & > button {
    display: inline-block;
    margin-right: 5px;
  }
}
