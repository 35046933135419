.loadingSpinner {
  position: absolute;
  top: 5px;
  left: -30px;
}

$listSelectColor: #e2f7ff;
$borderColor: #e8e8e8;

.listSelect {
  transition-duration: .3s;
  -webkit-transition-duration: .3s;
  border: 1px solid $borderColor;
  height: 235px;
  overflow: scroll;
}

.listItem {
  padding: 5px 0 5px 15px;
  width: 100%;
  transition-duration: .3s;
  -webkit-transition-duration: .3s;

  :local &:hover {
    background-color: $listSelectColor;
  }

  :local & > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.listItemEmpty {
  width: 100%;
  text-align: center;
}
