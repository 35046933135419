@import "shared/css/mixins.scss";

.logoutTimer {
  display: inline-block;
  color: #444444;
  margin-right: 10px;

  @include media-sm {
    display: none;
  }

  @include media-xs {
    display: none;
  }
}

.clockIcon {
  margin-right: 5px;
}

.extendText {
  margin-left: 5px;
  color: #1890ff;
  cursor: pointer;

  :local &:hover {
    text-decoration: underline;
  }
}

.warning {
  color: red;
}
