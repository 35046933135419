@import "shared/css/mixins.scss";

.rangePicker {
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.unit {
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 12px;
  color: #aaa;
}

.summary {
  background-color: #d8eeff;
  padding: 20px 60px;
  margin: 10px 20px 30px 20px;
  font-size: 24px;
  -webkit-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);
  box-shadow: 5px 5px 5px 0px rgba(214,214,214,1);

  @include media-lg {
    padding: 20px 30px;
  }

  @include media-md {
    font-size: 20px;
  }

  @include media-sm {
    padding: 20px 30px;
    font-size: 18px;
  }

  @include media-xs {
    padding: 20px 30px;
    font-size: 16px;
  }

  :local & > span {
    text-align: center;
    margin: 0 20px;

    @include media-lg {
      font-size: 20px;
      margin: 0 10px;
    }

    @include media-xs {
      display: block;
    }
  }
}

.summaryStatus {
  :global(.ant-badge > .ant-badge-status-dot) {
    width: 15px;
    height: 15px;

    @include media-sm {
      width: 10px;
      height: 10px;
    }

    @include media-xs {
      width: 8px;
      height: 8px;
    }
  }
}

.summaryBalance {
  margin-left: 80px;

  :local & > :global(.currency) {
    font-size: 20px;

    @include media-sm {
      font-size: 14px;
    }

    @include media-xs {
      font-size: 12px;
    }
  }

  @include media-sm {
    margin-left: 40px;
  }

  @include media-xs {
    margin-top: 10px;
    margin-left: 0px;
  }
}

:global(.ant-fullcalendar-fullscreen) {
  // Hide month/year selector
  :global(.ant-radio-group) {
    display: none;
  }
}

.calendarContainer {
  margin-top: 40px;

  :global(.ant-fullcalendar) {
    margin-top: 30px;
  }
}

.calendarMerchantSelect {
  position: absolute;
  top: 50px;
  left: 10px;
  width: 400px;
}

$calenderTitleWidth: 300px;

.calendarTitle {
  position: absolute;
  top: 90px;
  left: 50%;
  width: $calenderTitleWidth;
  margin-left: -($calenderTitleWidth / 2);
  text-align: center;

  :local & > * {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    padding: 0 10px;
  }

  :local & > i {
    position: absolute;
    top: 5px;
    cursor: pointer;

    &:hover {
      color: #0090ff;
    }
  }

  .calendarPreviousButton {
    left: 0;
  }

  .calendarNextButton {
    right: 0;
  }
}

.filterForm {
  :local & :global(.ant-row.ant-form-item) {
    margin-bottom: 5px;
  }
}

.leftAlignedDescriptionList {
  :local :global(.antd-pro-description-list-term) {
    width: 30%;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    white-space: unset;
    margin-right: 0;
  }

  :local :global(.antd-pro-description-list-detail) {
    width: 70%;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
  }
}

.settlementStatusText {
  position: absolute;
  top: 0px;
}

.downloadButton {
  margin-right: 10px;
}