.imageList {
  width: 100%;
  border: 1px solid #eee;
  padding: 10px;
}

.noImage {
  text-align: center;
}

.image {
  display: inline-block;
  margin-right: 10px;
}

:local .image > * {
  display: inline-block;
  vertical-align: top;
}

:local .image > img {
  width: 100px;
  height: 100px;
}

.imageDeleteButton {
  margin-left: 10px;
}
