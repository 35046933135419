.filterForm {
  :global(.ant-form-item) {
    margin-bottom: 12px;
  }
  :global(.ant-form-item-label) {
    line-height: 20px;
    padding: 0 0;
  }
  :global(.ant-form-item-label) > label {
    color: rgb(80,80,80);
    font-size: 12px;
    &:after {
      content: '';
    }
  }
}
