@import '~antd/lib/style/themes/default.less';

.prefixIcon {
  font-size: @font-size-base;
  color: @disabled-color;
}

.submit {
  width: 100%;
  margin-top: 24px;
}

.maskToggle {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    color: #1890ff;
    background-color: rgba(#81c2ff, 0.25f);
    border-radius: 5px;
  }
}

.passwordInput {
  :local input {
    // Chrome, FF, Opera, Safari 10.1+
    :local &::input-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      opacity: 1;
    }
  
    :local &::-webkit-input-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
  
    // IE10 - 11
    :local &:-ms-input-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
  
    // Edge
    :local &::-ms-input-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
  
    // FF18 and lower
    :local &:-moz-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
  
    // FF
    :local &::-moz-placeholder {
      font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
  }
}