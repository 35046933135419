/**
 *  Define scss variables here.
 */
/* default text color  */
.default_color {
  color: #596070;
}

.color1 {
  color: #FE4A49;
}

.color2 {
  color: #174C94;
}

.color3 {
  color: #022553;
}

.color4 {
  color: #919DAE;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.px6 {
  font-size: 8px;
}

.px8 {
  font-size: 8px;
}

.px10 {
  font-size: 10px;
}

.px12 {
  font-size: 12px;
}

.px14 {
  font-size: 14px;
}

.px16 {
  font-size: 16px;
}

.px18 {
  font-size: 18px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noPadding {
  padding: 0 !important;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .rangePicker {
    width: 250px;
  }
}

.buttonContainer {
  width: 120px;
}

.unit {
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 12px;
  color: #aaa;
}

.summary {
  background-color: #d8eeff;
  padding: 20px 60px;
  margin: 10px 20px 30px 20px;
  font-size: 24px;
  -webkit-box-shadow: 5px 5px 5px 0px #d6d6d6;
  -moz-box-shadow: 5px 5px 5px 0px #d6d6d6;
  box-shadow: 5px 5px 5px 0px #d6d6d6;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .summary {
    padding: 20px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .summary {
    font-size: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .summary {
    padding: 20px 30px;
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summary {
    padding: 20px 30px;
    font-size: 16px;
  }
}

:local .summary > span {
  text-align: center;
  margin: 0 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  :local .summary > span {
    font-size: 20px;
    margin: 0 10px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  :local .summary > span {
    display: block;
  }
}

.summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
  width: 15px;
  height: 15px;
}

@media (min-width: 576px) and (max-width: 767px) {
  .summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summaryStatus :global(.ant-badge > .ant-badge-status-dot) {
    width: 8px;
    height: 8px;
  }
}

.summaryBalance {
  margin-left: 80px;
}

:local .summaryBalance > :global(.currency) {
  font-size: 20px;
}

@media (min-width: 576px) and (max-width: 767px) {
  :local .summaryBalance > :global(.currency) {
    font-size: 14px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  :local .summaryBalance > :global(.currency) {
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .summaryBalance {
    margin-left: 40px;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .summaryBalance {
    margin-top: 10px;
    margin-left: 0px;
  }
}

:global(.ant-fullcalendar-fullscreen) :global(.ant-radio-group) {
  display: none;
}

.calendarContainer {
  margin-top: 40px;
}

.calendarContainer :global(.ant-fullcalendar) {
  margin-top: 30px;
}

.calendarMerchantSelect {
  position: absolute;
  top: 50px;
  left: 10px;
  width: 400px;
}

.calendarTitle {
  position: absolute;
  top: 90px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  text-align: center;
}

:local .calendarTitle > * {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  padding: 0 10px;
}

:local .calendarTitle > i {
  position: absolute;
  top: 5px;
  cursor: pointer;
}

:local .calendarTitle > i:hover {
  color: #0090ff;
}

.calendarTitle .calendarPreviousButton {
  left: 0;
}

.calendarTitle .calendarNextButton {
  right: 0;
}

:local .filterForm :global(.ant-row.ant-form-item) {
  margin-bottom: 5px;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-term) {
  width: 30%;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  white-space: unset;
  margin-right: 0;
}

.leftAlignedDescriptionList :local :global(.antd-pro-description-list-detail) {
  width: 70%;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
}

.settlementStatusText {
  position: absolute;
  top: 0px;
}

.downloadButton {
  margin-right: 10px;
}
